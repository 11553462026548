.PresetDateRangePicker_button {
    border: 2px solid #00ed76;
    color: #00ed76;
}
.PresetDateRangePicker_button__selected {
    background: #00ed76;
}
.DateInput_input__focused {
    border-bottom: 2px solid #00ed76;
}
.CalendarDay__selected_span {
    background: #00ed76;
    border: 1px double #00ed76;
    color: #111111;
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: #00ed76;
    border: 1px double #00ed76;
    color: #111111;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #00ed76;
    border: 1px double #00ed76;
    color: #111111;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: #00ed76;
    border: 1px double #00ed76;
    color: #00ed76;
    color: #111111;
}
.CalendarDay__hovered_span:active {
    background: #00ed76;
    border: 1px double #00ed76;
    color: #00ed76;
    color: #111111;
}

.DateInput {
    width: 270px;
}
.DateInput_input {
    font-size: 18px;
    line-height: 20px;
}
.DateInput_input__disabled {
    background: #fff;
    font-style: normal;
}
.SingleDatePickerInput__disabled {
    background-color: #fff;
}
.CalendarMonth_caption {
    color: #111111;
    text-transform: capitalize;
}
